import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Player from './Player';

const Router = () => {
  return (
    <Routes>
      <Route
        path='/:category/:playerId/live'
        element={
          <Player />
        }
      />
      <Route path='*' element={<Navigate to="/" />} />
    </Routes>
  );
};

export default Router;
