import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getStreams } from '../apis/fixture';
import './index.css';

const Player = () => {
  const { category,  playerId } = useParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [stream, setStream] = useState(null);
  const [loading, setLoading] = useState(true);

  const getChannelId = (data, category, channelId) => {
    if (data.schedule[category]) {
      const channels = data.schedule[category];
      for (let i = 0; i < channels.length; i++) {
        if (channels[i].channel_id.includes(channelId)) {
          return channels[i].channel_id; // Return channel ID when found
        }
      }
      return null; // Return null if no matching channel ID found
      
    } else {
      return null; // Category not found
    }
  };

  const getStreamData = async (channelId) => {
    setLoading(true);
    if(channelId){
      const response =  await getStreams(playerId);
      const data = response?.data[0];
      if(playerId === '1'){
        const stream = await getChannelId(data, category, channelId);
        setStream(stream);
        setLoading(false);
      }
    }
  }

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    const urlParams = new URLSearchParams(window.location.search);
    const chParam = urlParams.get('ch');
    getStreamData(chParam);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <iframe
          title="Video Player"
          width={screenWidth}
          height={screenHeight}
          src={stream}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
);
};

export default Player;
