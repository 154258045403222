/* eslint-disable no-console */
import Axios from 'axios';
import { STREAMERS } from '../utils/constants';
const SERVER_API = process.env.REACT_APP_SERVER_API ?? 'https://sportsfeed24-to-api-ziep2.ondigitalocean.app';


export const getStreams = async (streamer) => {
  try {
    const response = await Axios.get(STREAMERS[streamer]);

    return response;
  } catch (error) {
    return {
      error: "Bad Request"
    }
  }
};