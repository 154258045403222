import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Routes';

const App = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);

    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return (
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      // <div>
      //   <iframe
      //     title="Video Player"
      //     width={screenWidth}
      //     height={screenHeight}
      //     src={'https://stream.espnonline.site/live/embed.php?ch=ch72'}
      //     frameBorder="0"
      //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      //     allowFullScreen
      //   ></iframe>
      // </div>
  );
};

export default App;
